.responsive-object {
	position: relative;
	iframe,
	object,
	embed,
	img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}
