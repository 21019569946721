@use "global/bases";
@use "mixins";

.entity-list {
	&__intro,
	&__title {
		margin-bottom: var(--p-rhythm);
	}

	&__items {
		display: flex;
		flex-direction: column;
		gap: 84px;
		@include mixins.from-md {
			gap: var(--p-rhythm);
		}
		padding-bottom: var(--p-rhythm-lg);

		&--simple {
			padding: 0;
		}
	}

	&__link {
		display: grid;
		gap: var(--p-rhythm);

		&-text {
			display: flex;
			flex-direction: column;
			gap: 35px;
			justify-content: center;

			& > span {
				@include bases.base-link;
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;
				text-decoration: none;
				padding-left: 60px;

				&:before {
					display: inline-block;
					content: "";
					background: var(--t-accent-color);
					height: 2px;
					width: 50px;
					position: absolute;
					left: 0;
					top: 6px;
				}
			}

			& > p {
				font-size: 16px;
				line-height: 26px;
			}
		}

		@include mixins.from-md {
			grid-template-columns: 270px auto;
			margin-left: calc(-1 * var(--p-rhythm-sm));
			width: calc(100% + (2 * var(--p-rhythm-sm)));
		}
	}

	&__list {
		padding-top: var(--p-rhythm-lg);
	}

	&--full {
		.entity-list {
			&__list {
				border-top: 2px solid var(--t-accent-color);
			}
		}
	}
}
