.drag-drop {
	width: 100%;
	grid-column: span 2;
	&__area {
		width: 100%;
		height: 300px;
		background-color: #fafcfb;
		border: 2px dashed #e4f0eb;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1em;
		justify-content: center;
		transition: all 150ms ease-in-out;

		&.is-dragover {
			cursor: copy;
			border-style: solid;
			background-color: #e4f0eb;

			& > * {
				opacity: 0.7;
			}
		}
	}

	&__icon {
		width: 40px;
	}

	&__filename,
	&__instructions {
		font-size: var(--fs-body-lg);
	}

	&__browse {
		color: var(--t-accent-color);
		font-weight: bold;
		cursor: pointer;
	}

	&__controls {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		margin-top: 1em;
	}

	&__filename {
		display: flex;
		align-items: center;
		& > svg {
			width: 24px;
			height: 24px;
			margin-left: 8px;
		}
	}

	&__remove {
		text-decoration: underline;
		cursor: pointer;
		width: auto;
		margin-left: 1em;
	}
}
