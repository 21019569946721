@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
	letter-spacing: 0.05em;
}

@mixin body-font() {
	letter-spacing: 0.08em;
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	font-size: var(--fs-title);
	line-height: 1;
	font-weight: 700;
	letter-spacing: 0;
	margin: 0;
}

@mixin base-subheading() {
	font-family: constants.$body-font;
	font-size: var(--fs-subtitle);
	font-weight: 700;
	letter-spacing: 0;
	margin: 0;
}

@mixin base-link() {
	transition: all 150ms ease-in;
	color: inherit;
	display: inline-block;
	width: auto;
	position: relative;
	text-decoration: underline;
	text-decoration-color: var(--t-accent-color);
	text-underline-offset: 6px;
	text-decoration-thickness: 2px;

	&:hover,
	&:focus,
	&:active {
		text-decoration-thickness: 3px;
	}
}

%label {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
}
