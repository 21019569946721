.captioned-media {
	margin: 0;
	&__asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&__caption {
		font-size: 0.8em;
		padding-bottom: var(--p-rhythm);
		border-bottom: 1px solid black;
	}
}
