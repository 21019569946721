.bid-body {
	table {
		width: 100%;
		border-collapse: collapse;
		background-color: #fafcfb;

		+ p.table-caption {
			margin-top: -2em;
			font-size: 0.8em;
		}
	}

	td,
	th {
		border: 1px solid #e4f0eb;
		padding: 4px;
		text-align: center;
	}
}
