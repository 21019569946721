@use "global/bases";
@use "constants";

.entity-content {
	&__blocks {
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);
	}

	& h4 {
		margin-bottom: 1em;
	}

	.link-list {
		display: flex;
		flex-direction: column;
		gap: 1em;

		& > a {
			@include bases.base-link;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	.single-link {
		@include bases.base-link;
		font-size: 24px;
		font-family: constants.$heading-font;
		padding: calc(40px - var(--p-rhythm-sm)) 0;
	}
}
