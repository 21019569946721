@use "mixins";
@use "constants";
@use "global/bases";

.form-field {
	width: 100%;

	&__field {
		display: block;
		line-height: 1.3;
		background: none;
	}

	& textarea {
		height: 200px;
	}

	&__field {
		font-size: var(--fs-form-control);
		padding: 0.8em;
		width: 100%;
		appearance: none;
		border-radius: 10px;
		background-color: #fafcfb;
		border: 2px solid #e4f0eb;
	}

	&__boolean-group {
		> * + * {
			margin-top: calc(var(--p-rhythm-sm) / 2);
		}
	}

	&__label {
		@extend %label;
		margin-bottom: 4px;
		display: block;

		& + .form-field__help-text {
			margin-top: -3px;
		}

		& > .room-label {
			color: var(--t-accent-color);
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&__help-text {
		margin-bottom: 6px;
	}

	&__pair {
		display: grid;
		grid-template: repeat(2, 1fr);
		grid-auto-flow: row;
		align-items: end;
		gap: var(--field-gap);

		@include mixins.from-md {
			grid-auto-flow: column;
		}
	}

	&__triple {
		display: grid;
		grid-template: repeat(3, 1fr);
		grid-auto-flow: row;
		align-items: end;
		gap: var(--field-gap);

		@include mixins.from-md {
			grid-auto-flow: column;
		}
	}

	&--select {
		width: 100%;
		position: relative;
		.form-field {
			&__field {
				background-color: transparent;
				padding-right: 42px;
			}
		}
		& svg {
			width: 20px;
			position: absolute;
			right: 16px;
			top: 52px;
			transition: transform 300ms ease-in-out;
		}

		@include mixins.from-md {
			min-width: 400px;
			& svg {
				top: 32px;
			}
		}
	}

	&--yes-no {
		& .form-field {
			&__boolean-group {
				display: flex;
				gap: 1rem;
			}
		}
	}

	@include mixins.from-md {
		&__label {
			margin-bottom: 9px;
		}
		&--columned {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 2rem;
			.form-field {
				&__label {
					grid-column: 1;
				}

				&__field {
					grid-column: 2 / 4;
				}
			}
		}
	}
}
