@use "constants";
@use "mixins";

.entity-list-item {
	&__title {
		font-family: constants.$body-font;
		font-size: var(--fs-subtitle);
		margin-bottom: var(--p-rhythm);
	}

	&__images {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;

		@include mixins.from-md {
			margin: 0 calc(-1 * var(--p-rhythm-sm));
			gap: var(--p-rhythm);
		}

		& > img:first-child {
			grid-column: span 3;
		}
	}

	&__content {
		margin-top: var(--p-rhythm);
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm);
	}

	& + & {
		margin-top: var(--p-rhythm-lg);
	}

	&--simple {
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);

		& .entity-list-item {
			&__content {
				margin-top: 1em;
				gap: 1.5em;
			}
		}

		// @include mixins.from-md {
		// 	grid-template-columns: 270px auto;
		// 	margin-left: calc(-1 * var(--p-rhythm-sm));
		// 	width: calc(100% + 2 * var(--p-rhythm-sm));
		// }
	}
}
