@use "constants";
@use "mixins";
@use "global/bases";

@forward "fonts";

html {
	width: 100%;
	overflow-x: hidden;
	background: var(--t-page-bg-color);
	scroll-behavior: smooth;
}

body {
	@include bases.body-font;
	font-size: 16px; // Changing this will impact rem based calculations
	line-height: 1.4;
	color: var(--t-text-color);
	font-weight: 300;
	margin: 0;
	font-size: var(--fs-body);

	&.preload {
		* {
			transition: none !important;
		}
	}

	@include mixins.to-md {
		overflow: hidden;
	}
}

h1 {
	@include bases.base-heading;
}

h2 {
	@include bases.base-heading;
	font-weight: 400;
}

h3 {
	// Subheadings
	@include bases.base-subheading;
}

h4 {
	// In content headings?
	@include bases.base-subheading;
	text-transform: uppercase;
	font-size: 0.8em;
	letter-spacing: 1.5px;
}

.lede {
	font-size: var(--fs-body-lg);
}

.heading {
	margin-bottom: var(--p-rhythm);
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include bases.base-link;
		display: inline;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
		td {
			padding-top: 0.1em;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
	}

	&-image {
		position: relative;
		margin-left: calc(-1 * var(--p-rhythm-sm));
		width: calc(100% + 2 * var(--p-rhythm-sm));
	}

	b,
	strong {
		font-weight: bold;
	}
}

@include mixins.from-md {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}

		&-image {
			&:first-child {
				margin-top: 0;
			}
			&.left,
			&.right {
				max-width: calc(100% / 3);
				display: block;
			}
			&.right {
				float: right;
				margin-left: var(--p-rhythm-sm);
			}

			&.left {
				float: left;
				margin-right: var(--p-rhythm-sm);
			}
		}
	}
}
