@use "constants";
@use "mixins";
@use "global/bases";

.requirement-list {
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: var(--p-rhythm);

	&__item {
		border-bottom: 1px solid var(--t-border);
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm);

		@include mixins.from-md {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__date {
		grid-column: 1;

		&-inner {
			position: sticky;
			top: var(--p-rhythm);
			bottom: var(--p-rhythm);
			display: flex;
			flex-direction: column;
			font-weight: bold;

			& > span:first-child {
				@extend %label;
			}

			& > span:last-child {
				font-size: var(--fs-subtitle);
				font-family: constants.$heading-font;
				line-height: 1;
			}

			&--static {
				position: static;
			}
		}
	}

	&__forms {
		grid-column: 2 / 4;
	}

	&__forms,
	&__row {
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm);
	}

	&__row {
		padding-bottom: var(--p-rhythm);
		border-bottom: 1px solid var(--t-border);
	}

	&__reqs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;

		@include mixins.from-md {
			grid-template-columns: repeat(3, 1fr);
		}
		& > div {
			display: flex;
			flex-direction: column;
			& > span:first-child {
				@extend %label;
			}
			& > span:last-child {
				font-size: var(--fs-body-lg);
				font-weight: bold;
				font-family: constants.$heading-font;
			}

			&:first-child {
				grid-column: 1 / 4;

				& > span:last-child {
					font-size: var(--fs-subtitle);
					line-height: 1;
				}
			}
		}
	}

	&__form-row {
		display: flex;
		flex-direction: column;
		gap: var(--field-gap);
		position: relative;
	}
}
