/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 320px;
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1920px;

/**
  * Font definitions
  */
$body-font: "Manrope", sans-serif;
$heading-font: "DM Serif Display", serif;

/**
  * Transitions
  */
$base-anim-rhythm: 300ms;
$transition-default: all $base-anim-rhythm ease-in-out;
