@use "global/bases";
@use "mixins";

.bid-intro {
	h2 {
		margin-bottom: var(--p-rhythm);
	}

	&__meta {
		margin-top: var(--p-rhythm);
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	&__pair {
		display: flex;
		flex-direction: column;

		span {
			&:first-child {
				font-weight: 700;
				font-size: 14px;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				margin-bottom: 1em;
			}
			&:nth-child(2) {
				@include bases.base-heading;
				font-size: var(--fs-body-lg);
				font-weight: normal;
			}
		}
	}
	@include mixins.to-md {
		&__meta {
			grid-template-columns: 1fr;
		}
		&__pair {
			span {
				&:first-child {
					margin-bottom: 0.5em;
				}
				&:nth-child(2) {
					margin-bottom: 1.5em;
				}
			}
			&:last-of-type {
				span {
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
