@use "mixins";
@use "constants";

.key-message {
	&__inner {
		& > p {
			margin-top: 1em;
			align-self: center;
			text-align: center;
			font-family: constants.$heading-font;
			font-size: var(--fs-body-lg);
			line-height: 1.625;
			letter-spacing: 0;
		}
		@include mixins.from-md {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-left: calc(-1 * var(--p-rhythm-sm));
			width: calc(100% + (2 * var(--p-rhythm-sm)));

			& > p {
				margin-top: 0;
				padding-left: var(--p-rhythm);
			}
		}
	}
}
