@use "constants";

// Inclusive of the value and above.
@mixin from($value) {
	@media screen and (min-width: $value) {
		@content;
	}
}

// Up to but not inclusive (1px less) the value:
@mixin to($value, $legacy: null) {
	@media screen and (max-width: ($value - 1)) {
		@content;
	}
}

@mixin from-to($min, $max) {
	@media screen and (min-width: $min) and (max-width: ($max -1)) {
		@content;
	}
}

@mixin from-sm() {
	@include from(constants.$bp-small) {
		@content;
	}
}

@mixin from-md() {
	@include from(constants.$bp-medium) {
		@content;
	}
}

@mixin from-lg() {
	@include from(constants.$bp-large) {
		@content;
	}
}

@mixin to-sm() {
	@include to(constants.$bp-small) {
		@content;
	}
}

@mixin to-md() {
	@include to(constants.$bp-medium) {
		@content;
	}
}

@mixin to-lg() {
	@include to(constants.$bp-large) {
		@content;
	}
}
