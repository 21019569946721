@use "mixins";

.bid-contact {
	margin: var(--p-rhythm-lg) 0 20px 0;
	@include mixins.from-md {
		margin: var(--p-rhythm-lg) calc(-1 * var(--p-rhythm-sm));
	}

	&__text {
		color: white;
		background: #373535;
		padding: var(--p-rhythm);

		& h2 {
			line-height: 1.2;
		}
	}

	&__link {
		width: auto;
		transition: text-decoration 150ms ease-in-out;
		text-decoration-thickness: 2px;
		text-underline-offset: 4px;
		text-decoration: underline;
		text-decoration-color: transparent;

		& + & {
			margin-top: 1em;
		}

		&:focus,
		&:hover {
			text-decoration-color: white;
		}
	}

	@include mixins.from-md {
		&__text {
			padding: var(--p-rhythm) 25% var(--p-rhythm) 25%;
		}
	}
}
