@use "sass:math";
@use "components/buttons";
$control-size: 1em;

.form-boolean {
	position: relative;
	input {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;

		&:checked {
			& ~ .form-boolean__label:before {
				border-color: var(--t-accent-color);
			}
		}
	}
	&__label {
		position: relative;
		font-size: var(--fs-form-control);
		padding-left: 1.8em;
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{math.div($control-size, 2)};
		}
		&:before {
			transition: border 150ms ease-in-out;
			border: 2px solid #e4f0eb;
		}
	}

	&--checkbox {
		position: relative;
		& .form-boolean {
			&__icon {
				$icon-size: 26px;
				width: $icon-size;
				height: $icon-size;
				position: absolute;
				top: 4px;
				left: -1px;
				& > svg {
					path {
						stroke-dasharray: 100;
						stroke-dashoffset: 100;
						fill: none;
					}
				}
			}
		}

		& input:checked + .form-boolean__icon {
			path {
				transition: 1s linear;
				stroke-dashoffset: 0;
			}
		}
	}

	&--radio {
		& .form-boolean {
			&__label {
				&:after {
					transition: transform 200ms ease-in-out;
					background: var(--t-accent-color);
					transform: scale(0);
				}
				&:before,
				&:after {
					border-radius: 100%;
				}
				&:hover {
					&:after {
						transform: scale(0.5);
					}
				}
			}
		}
		& input:checked + .form-boolean__label {
			&:after {
				transform: scale(0.65);
			}
		}
	}

	&--yes-no {
		margin-top: 0 !important;
		& .form-boolean {
			&__label {
				@extend .btn;
				@extend .btn--inverted;
				&:after,
				&:before {
					content: none;
				}
			}
		}
		& input:checked + .form-boolean__label {
			background-color: var(--t-accent-color);
			color: white;
		}
	}

	&__help-text {
		margin-top: 12px;
	}
}

.form-boolean-group {
	&__label {
		display: block;
		margin-bottom: 12px;
	}
	.form-boolean {
		+ .form-boolean {
			margin-top: var(--p-rhythm-sm);
		}
	}
}
