.l-prose-blocks {
	&__title {
		font-size: var(--fs-title);
		margin-top: 3em;
		margin-bottom: 1em;
	}

	> * + * {
		margin-top: var(--p-rhythm-lg);
	}
}
