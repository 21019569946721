@use "mixins";

.alternative-dates {
	display: flex;
	flex-direction: column;
	gap: var(--p-rhythm);
	padding-bottom: var(--p-rhythm);
	border-bottom: 1px solid var(--t-border);
	position: relative;

	&__form {
		grid-column: span 2;
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);
	}

	&__spinner {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: -70px;
		opacity: 0;
		& > svg {
			transform-origin: center;
			animation: spinner 0.75s infinite linear;

			@keyframes spinner {
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}

	@include mixins.from-md {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}
