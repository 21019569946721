.blockquote {
	padding-left: var(--p-rhythm);
	border-left: 2px solid black;
	margin: 0;
	&__body {
	}
	&__citation {
		font-style: italic;
	}
}
