@use "constants";
@use "mixins";
@use "global/bases";

.l-dashboard {
	&__title {
		// FIXME same as l-feedback__title
		text-align: center;
		margin: var(--p-rhythm-lg) 0 calc(var(--p-rhythm-lg) * 1.5) 0;

		h1 {
			letter-spacing: 6px;
			font-size: var(--fs-title);
			font-family: constants.$body-font;
			text-transform: uppercase;
		}

		@include mixins.to-md {
			margin-bottom: var(--p-rhythm-lg);
		}
	}

	&__intro {
		color: white;
		background-color: var(--t-accent-color);
		padding: var(--p-rhythm);
		margin: var(--p-rhythm-lg) 0;

		& a {
			text-decoration-color: white !important;
		}
	}

	&__stats {
		display: flex;
		justify-content: space-between;
		margin-bottom: calc(var(--p-rhythm-lg) * 1.5);

		&__pair {
			display: flex;
			flex-direction: column;

			& > span:first-child {
				@extend %label;
			}
			& > span:last-child {
				margin-top: 5px;
				font-family: constants.$heading-font;
				font-size: 24px;
				line-height: 1.1;
			}
		}

		@include mixins.to-md {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;
			margin-bottom: var(--p-rhythm-lg);
		}
	}

	&__heading {
		@include bases.base-heading;
		font-weight: 400;
		margin-bottom: 45px;

		@include mixins.from-md {
			margin-bottom: var(--p-rhythm);
		}
	}

	&__empty {
		font-family: constants.$heading-font;
		font-size: var(--fs-body-lg);
		margin-bottom: var(--p-rhythm-lg);
	}

	table {
		width: 100%;
		th {
			text-transform: uppercase;
			text-align: left;
		}
		tbody {
			td {
				font-size: 24px;
				font-family: constants.$heading-font;
				line-height: 1.25;
				padding: 1rem 1.5rem 1rem 0;
				vertical-align: top;

				&.no-break {
					white-space: nowrap;
				}
			}
			tr:not(:last-child) {
				td {
					border-bottom: 1px solid var(--t-border);
				}
			}
		}

		@include mixins.to-md {
			display: none;
		}
	}

	&__mobile-listing {
		padding: var(--p-rhythm) 0;
		margin: 0;

		dd {
			font-family: constants.$heading-font;
			margin: 0;
			font-size: 24px;
			line-height: 1.25;

			+ dt {
				margin-top: var(--p-rhythm-sm);
			}

			&.break-inside {
				word-break: break-all;
			}
		}

		dt {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid var(--t-border);
		}

		@include mixins.from-md {
			display: none;
		}
	}

	&__table-container {
		margin-bottom: calc(var(--p-rhythm-lg) * 1.5);

		@include mixins.from-md {
			flex-direction: column;
			display: flex;
			gap: var(--p-rhythm-lg);
			align-items: center;
		}

		@include mixins.to-md {
			.btn {
				width: 100%;
			}
		}
	}

	&__bet-stats {
		color: white;
		background-color: var(--t-accent-color);
		padding: var(--p-rhythm);
		margin: var(--p-rhythm-lg) 0;

		& > h3 {
			@include bases.base-heading;
			font-weight: 400;
		}

		&__table {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
			margin-top: var(--p-rhythm-sm);

			@include mixins.from-md {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		&__pair {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			& > span {
				&:first-child {
					@include bases.base-heading;
					font-weight: 400;
				}

				&:last-child {
					text-transform: uppercase;
					font-weight: bold;
					line-height: 1;
					font-size: 14px;
				}
			}
		}
	}
}
