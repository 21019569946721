@use "constants";
@use "mixins";
@use "sass:math";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return math.div($number, ($number * 0 + 1));
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: math.div(strip-units($minWidthPx), 16);
	$maxWidth: math.div(strip-units($maxWidthPx), 16);
	$minFontSize: math.div(strip-units($minFontSizePx), 16);
	$maxFontSize: math.div(strip-units($maxFontSizePx), 16);

	$slope: math.div(($maxFontSize - $minFontSize), ($maxWidth - $minWidth));
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--t-text-color: #132433;
	--t-accent-color: #4aa27c;
	--t-page-bg-color: #ffffff;
	--t-border: #ebebeb;

	--p-rhythm: 32px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-gutter: min(5vw, 75px);
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1400px);

	--field-gap: 24px;

	@include gen-clamp("--fs-body", 16px, 16px);
	@include gen-clamp("--fs-body-lg", 18px, 20px);
	--fs-form-control: 24px;
	@include gen-clamp("--fs-title", 30px, 50px);
	@include gen-clamp("--fs-subtitle", 28px, 34px);

	@include mixins.from-md {
		--p-rhythm-sm: 40px;
		--p-rhythm: 50px;
		--p-rhythm-lg: 100px;

		--field-gap: var(--p-rhythm-sm);
	}
}
