@use "constants";
@use "mixins";
@use "global/bases";

.room-request {
	display: flex;
	flex-direction: column;
	gap: var(--p-rhythm);

	border-bottom: 1px solid var(--t-border);

	&__date {
		display: flex;
		flex-direction: column;

		& > span:first-child {
			@extend %label;
		}

		& > span:last-child {
			font-size: var(--fs-subtitle);
			line-height: 1;
			font-family: constants.$heading-font;
		}
	}

	&__row {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm);
		padding-bottom: var(--p-rhythm);

		@include mixins.from-md {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__requirement {
		grid-column: 1;
		display: flex;
		flex-direction: column;

		& > span:first-child {
			@extend %label;
		}

		& > span:last-child {
			font-size: var(--fs-form-control);
			line-height: 1;
			font-family: constants.$heading-font;
		}
	}

	&__response {
		grid-column: 2 / 4;
		display: flex;
		flex-direction: column;
		gap: 18px;
	}
}
