.message {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	padding: 1rem;
	font-weight: bold;
	text-align: center;
	animation: popup 7s ease-in-out forwards;

	&--success {
		background-color: var(--t-accent-color);
		color: white;
	}

	&--error {
		background-color: #ce0e0e;
		color: white;
	}
}

@keyframes popup {
	0% {
		transform: translateY(-100%);
	}

	10% {
		transform: translateY(0);
	}

	90% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}
