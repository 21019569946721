@use "mixins";

.l-page {
	max-width: var(--page-width);
	margin: 0 auto;

	&__error {
		margin-top: var(--p-rhythm-sm);
	}

	&--thin {
		max-width: 820px;
	}

	&__section + &__section {
		margin-top: var(--p-rhythm-lg);
	}
}
