@use "mixins";

.btn {
	display: inline-block;
	width: auto;
	background-color: var(--t-accent-color);
	border: 2px solid var(--t-accent-color);
	color: white;

	font-size: var(--fs-body);
	line-height: 1;
	font-weight: bold;

	padding: 10px;
	border-radius: 10px;
	transition: all 200ms ease-in-out;
	text-align: center;
	cursor: pointer;
	min-width: 160px;

	&:hover,
	&:focus,
	&:active {
		background-color: white;
		color: var(--t-text-color);
	}

	&--inverted {
		background-color: white;
		color: var(--t-text-color);

		&:hover,
		&:focus,
		&:active,
		&.btn--active {
			background-color: var(--t-accent-color);
			color: white;
		}
	}

	&--submit {
		width: 100%;
	}

	@include mixins.from-md {
		padding: 10px 15px;

		&--submit {
			width: 400px;
		}
	}
}

.add-btn {
	display: inline-block;
	width: auto;
	align-self: self-start;
	border-bottom: 2px solid var(--t-accent-color);
	letter-spacing: 1.5px;
	padding-bottom: 4px;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;

	& svg {
		margin-left: 1.5rem;
		transition: transform 200ms ease-in-out;
	}

	&:hover {
		svg {
			transform: rotate(90deg);
		}
	}
}

.delete-btn {
	width: 18px;
	height: auto;
	position: absolute;
	top: 54px;
	right: 18px;
	cursor: pointer;
	transition: color 200ms ease-in-out;

	&:hover {
		color: var(--t-accent-color);
	}

	@include mixins.from-md {
		top: 58px;
	}
}
