@use "constants";
@use "mixins";

.l-site-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 100;
	padding: var(--p-rhythm-sm) var(--page-gutter);
	margin: 0 auto;
	margin-bottom: calc(40px - var(--p-rhythm-sm));
	&__logo {
		display: block;
		height: 32px;
		img {
			width: auto;
			height: 100%;
		}
	}
}

@include mixins.to-md {
	.l-site-header {
		[data-print] {
			display: none;
		}
	}
}

@include mixins.from-md {
	.l-site-header {
		padding: 1em var(--p-rhythm-sm);

		margin-bottom: var(--p-rhythm-lg);

		&__logo {
			width: 100px;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}
