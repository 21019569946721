@use "mixins";
@use "constants";
@use "global/bases";

.bid-body {
	&__content {
		& > section {
			padding-top: var(--p-rhythm-lg);
			padding-bottom: var(--p-rhythm-lg);
			border-top: 2px solid var(--t-accent-color);

			&:first-of-type {
				padding-top: 0;
				border-top: none;
			}
		}
	}

	&__intro {
		padding-top: var(--p-rhythm-lg);
	}

	&__heading {
		font-size: var(--fs-title);
		font-family: constants.$body-font;
		letter-spacing: 6px;
		padding-top: calc(var(--p-rhythm-lg) * 4);
		padding-bottom: 2em;
		text-transform: uppercase;
		font-weight: 700;
	}

	&__nav {
		display: flex;
		flex-direction: column;

		& > a {
			border-bottom: 1px solid #e9ebec;
			padding: 0.5em 0;
			font-weight: bold;
			color: #6e7881;
			font-size: 16px;

			&.is-active,
			&:hover {
				color: #000;
			}

			&:last-of-type {
				border: none;
			}
		}
	}

	@include mixins.from-md {
		display: grid;
		grid-template-areas:
			". heading"
			"aside content";
		grid-template-columns: 230px auto;
		grid-template-rows: auto auto;
		column-gap: calc(var(--p-rhythm) * 2);
		row-gap: 0;

		&__nav {
			top: var(--p-rhythm-lg);
			position: sticky;
		}

		&__aside {
			grid-area: aside;
		}

		&__content {
			grid-area: content;
		}

		&__intro {
			grid-area: heading;
			padding-top: 0;
		}
	}
}
