@use "constants";

.bid-fold {
	text-transform: uppercase;
	text-align: center;
	padding-bottom: calc(var(--p-rhythm-lg) * 2.5);
	&__title {
		font-size: var(--fs-title);
		font-family: constants.$body-font;
		letter-spacing: 6px;
		margin-top: calc(var(--p-rhythm) * 2);
	}

	&__subtitle {
		display: block;
		margin-top: var(--p-rhythm-sm);
		font-weight: 700;
	}
}
