@use "mixins";

.contours {
	position: absolute;
	overflow: hidden;
	z-index: -1;

	& > img {
		width: 1600px;
		height: 1400px;
	}

	&--top {
		top: -800px;
		left: -800px;
	}

	&--bottom {
		left: -800px;
		bottom: 0;
		max-height: 100vw;
	}

	@include mixins.from-md {
		&--top {
			top: -85px;
		}

		&--bottom {
			left: -402px;
			bottom: -74px;
		}
	}
}
