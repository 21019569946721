show-hide {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--p-rhythm);
}

show-hide-target {
	& > * + * {
		margin-top: var(--p-rhythm);
	}
}
