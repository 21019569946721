@use "constants";
@use "mixins";
@use "global/bases";

.l-feedback {
	&__title {
		text-align: center;
		margin: calc(var(--p-rhythm) * 2) 0;

		h1 {
			letter-spacing: 6px;
			font-size: var(--fs-title);
			font-family: constants.$body-font;
			text-transform: uppercase;
		}
		h3 {
			margin-top: 14px;
			font-family: constants.$body-font;
			line-height: 1;
			letter-spacing: 1.4px;
			font-size: var(--fs-body-lg);
			text-transform: uppercase;
		}
	}

	&__bid-info {
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);

		&__grid {
			gap: var(--p-rhythm-sm);
			display: flex;
			flex-direction: column;

			@include mixins.from-md {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
			}
		}
		&__pair {
			display: flex;
			flex-direction: column;

			& > span:first-child {
				@extend %label;
			}
			& > span:last-child {
				margin-top: 5px;
				font-family: constants.$heading-font;
				font-size: 24px;
				line-height: 1.1;
			}

			&--big {
				align-self: center;
				text-align: center;
				& > span:last-child {
					font-size: 36px;
				}
			}
		}
	}

	&__req-table {
		margin-top: var(--p-rhythm-lg);
		width: 100%;

		&__heading {
			font-size: var(--fs-title);
			font-weight: bold;
			font-family: constants.$heading-font;
			line-height: 1.1;
			margin-bottom: var(--p-rhythm-sm);
		}

		& table.listing {
			width: 100%;
			border-collapse: collapse;

			th {
				text-transform: uppercase;
				font-weight: bold;
				text-align: left;
			}
			th,
			td {
				border-bottom: 1px solid var(--t-border);
				padding: 1rem 0;

				&:not(:first-child) {
					padding-left: 1rem;
				}
			}

			td {
				font-size: var(--fs-body-lg);
			}
		}
	}

	&__question {
		margin-top: var(--p-rhythm-lg);
		grid-column: span 3;
		font-size: var(--fs-title);
		font-weight: bold;
		font-family: constants.$heading-font;
		line-height: 1.1;

		& b {
			color: var(--t-accent-color);
		}
	}

	&__response-buttons {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: var(--p-rhythm-sm);

		@include mixins.from-md {
			flex-direction: row;
		}
	}

	&__intro {
		font-size: var(--fs-body-lg);
		margin-bottom: var(--p-rhythm-lg);
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm);
		align-items: flex-start;
		margin: var(--p-rhythm-lg) 0;
	}

	&__validation-message {
		font-size: var(--fs-body-lg);
		margin-top: var(--p-rhythm);
		color: red;
		text-align: center;
		font-weight: bold;
	}

	&__section-title {
		margin-top: calc(var(--p-rhythm-lg) * 2);
	}

	&__prefill {
		width: 100%;
		font-size: var(--fs-body-lg);
		text-align: center;
	}

	&__submit {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
		align-items: center;
		margin-top: calc(100px - var(--p-rhythm));

		@include mixins.from-md {
			margin-top: calc(200px - var(--p-rhythm));
		}
	}
}
